import React, { Component } from "react";

export default class componentName extends Component {
  componentDidMount() {
    const isOSX = !!navigator.platform.match(/(Mac|iPhone|iPod|iPad)/i);

    const appStore = "https://itunes.apple.com/us/app/numu/id1323537121?mt=8";
    const playStore =
      "https://play.google.com/store/apps/details?id=com.numu.app";

    if (isOSX) {
      window.location.href = appStore;
    } else {
      window.location.href = playStore;
    }
  }

  render() {
    return (
      <div
        aria-busy="true"
        aria-label="Loading"
        role="progressbar"
        className="container"
      >
        <div className="swing">
          <div className="swing-l" />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div className="swing-r" />
        </div>
        <div className="shadow">
          <div className="shadow-l" />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div className="shadow-r" />
        </div>
      </div>
    );
  }
}
